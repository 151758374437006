<template>
  <v-container fluid>
    <v-row>
      <v-col md="3" sm="3">
        <v-text-field
          :value="search"
          @input="$emit('changeSearch', $event)"
          label="Search"
          clearable
          append-icon="mdi-magnify"
          dense
          outlined
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <!--Bulk Actions Btn-->
      <v-col md="3" sm="3">
        <InvoicesBulkActionsBtn
          :bulkActions="bulkActions"
          :bulkMenuItems="bulkMenuItems"
          @changeBulkActions="$emit('changeBulkActions', $event)"
          @changeBulkInvoices="$emit('changeBulkInvoices', $event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "InvoiceTableFilters",
  props: ["search", "bulkActions", "bulkMenuItems"],
  components: {
    InvoicesBulkActionsBtn: () =>
      import("../Buttons/InvoicesBulkActionsBtn.vue"),
  },
  data() {
    return {};
  },
};
</script>